'use client';

import type { FC } from 'react';

import { useColorPicker } from 'react-best-gradient-color-picker';

import type { Platform } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/customize/types';
import { PlatformIcons } from '@/components/human-routing/platform-item.component';
import { addAlpha, getContrast, getTextColor } from '@/utils/colors';

type SuggestedMessageProps = {
  handleClick?: () => void;
  backgroundColor?: string;
  platform?: Platform;
  value: string;
  tooltip?: string;
  isLoading?: boolean;
};

const SuggestedMessage: FC<SuggestedMessageProps> = ({
  handleClick,
  backgroundColor,
  platform,
  value,
  tooltip,
  isLoading = false,
}) => {
  const Icon = platform ? PlatformIcons[platform] : null;

  const { valueToHex } = useColorPicker(backgroundColor, () => {});
  let textColor = valueToHex();
  let borderColor = addAlpha(textColor, 0.4);

  const whiteContrast = getContrast(textColor, '#ffffff');

  if (whiteContrast < 2) {
    textColor = getTextColor(textColor);
    borderColor = addAlpha(textColor, 0.4);
  }

  return (
    <button
      type="button"
      id="suggested-message"
      className="flex items-center rounded-full px-[15px] py-[8px] transition-transform duration-300 ease-in-out hover:-translate-y-[2px] disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:translate-y-0"
      onClick={() => handleClick && handleClick()}
      disabled={isLoading}
      style={{
        color: textColor,
        border: `1px solid ${borderColor}`,
      }}
      {...(tooltip
        ? {
            'data-tooltip-id': 'messenger-tooltip',
            'data-tooltip-content': tooltip,
            'data-tooltip-offset': 10,
          }
        : {})}
    >
      {Icon && <Icon width="16px" height="16px" className="mr-[5px]" />}
      <p className="text-[12px] font-medium leading-[18px]">{value}</p>
    </button>
  );
};

export default SuggestedMessage;
